
  
  
  
  ul,
  li {
    padding: 0;
    margin: 0;
  }
  
  ul {
    width: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    list-style: none;
  }
  
  li {
    font-size: 2rem;
    margin-bottom: 1.6rem;
  }
  
  * {
    font-family: sofia-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
  }
  
  
  
  .progress {
    position: fixed;
    left: 0;
    right: 0;
    height: 5px;
    background: var(--accent);
    bottom: 100px;
  }
  
  
  