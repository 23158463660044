.nav-container{
    z-index: 999;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    /* transition-delay: 1s; */
  }
  
  nav {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
  }
  
  .background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background: #1C7ED6;
  }
  
  button {
    outline: none;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    position: absolute;
    top: 18px;
    left: 15px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: transparent;
  }
  
  ul,
  li {
    margin: 0;
    padding: 0;
  }
  
  ul {
    padding: 25px;
    position: absolute;
    width: 230px;
  }
  
  li {
    list-style: none;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .text-placeholder {
    text-decoration: none;
    color: #fff;
    border-radius: 5px;
    padding: 0.5rem;
    flex: 1;
  }
  
  /* .refresh {
    padding: 10px;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  } */