
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap');

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body{
  
    max-width: 100%;

}

